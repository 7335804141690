/* You can add global styles to this file, and also import other style files */
@import "./assets/fonts/font.scss";

/* Importing Bootstrap SCSS file. */
@import "src/theme/css/light";
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

body {
  margin: 0;
  font-family: 'Titillium Web';
}

form.ng-submitted {
  .ng-invalid {
    @extend .is-invalid !optional;
  }
}

.ngb-dp-content.ngb-dp-months {
  //small screen size
  @media (max-width: 576px) {
    flex-direction: column;
  }
}

.toast-header {
  background-color: transparent;
  color:black !important;
}

.search-results {
  overflow-y: scroll;
  max-height: calc(100vh - 210px);
  // Small and medium devices
  @media (max-width: 576px), (min-width: 577px) and (max-width: 992px) {
    max-height: calc(100vh - 200px);
  }
  .card {
    position: relative;
    background-color: #fff; /* Keep cards clean */
    border-radius: 8px; /* Slight rounding for modern feel */
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    z-index: 1; /* Prevent overlap with touched-card */
  }

  .card:hover {
    transform: translateY(-5px) scale(1.02); /* Lift slightly and enlarge */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Add shadow */
    z-index: 2; /* Elevate hovered card */
  }

  .touched-card {
    position: relative;
    z-index: 3; /* Ensure it stays visually above hovered cards */
    animation: backgroundPulse 2s ease-in-out forwards; /* Smooth fade */
  }

  @keyframes backgroundPulse {
    0% {
      background-color: rgba(var(--bs-primary-rgb), 0.4); /* Soft glow */
    }
    50% {
      background-color: rgba(var(--bs-primary-rgb), 0.8); /* Brighten */
    }
    100% {
      background-color: transparent; /* Fade to normal */
    }
  }
}

.error-input {
  margin-left: 10px;
  color: var(--bs-danger);
}

.warning-input {
  margin-left: 10px;
  color: var(--bs-warning);
}

.form-control {
  height: calc(1.25em + 0.75rem + 2px);
  font-size: 0.9rem;
  border-radius: 0;
}

.ng-invalid:not(form):not(.ng-untouched) {
  border: none;
  box-shadow: 0 0 5px 2px var(--bs-danger);
}

.form-row {
  label {
    font-weight: bold;
    color: var(--bs-gray-600);
    font-size: 14px;
    padding-bottom: 5px;
  }
}

.label-required::after {
  content: ' *';
  color:var(--bs-danger);
}

.radio-group {
  width: 100%;
}

.radio-button {
  padding: 0px;

  label {
    padding-left: 10px;
    text-align: left;
  }
}

.btn-equal-size {
    min-width: 100px;
}

@media (max-width: 768px) { /* Adjust the max-width as needed for your mobile/small screen devices */
  .btn-equal-size {
     min-width: 30px;
  }
}

.scrollable {
  max-height: 75vh;
  overflow-y: auto !important;
}

.status-tag {
  background: linear-gradient(45deg, var(--bs-primary), var(--bs-info));
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 25px;
  font-weight: bold;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  font-size: 0.7rem;
  text-transform: uppercase;
  display: inline-block;
}
